import { DestroyRef, Injectable } from '@angular/core';
import { combineLatest, mergeMap, Observable, pairwise, shareReplay, switchMap } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { IRestaurantTable } from '../models';
import { AuthService } from './auth.service';
import { WatchdogService } from './watchdog.service';
import { WebsocketService } from './websocket.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from './interaction.service';
import { Intercom } from './intercom.service';
import { KVRepository } from '../repositories';
import { ClusterService } from './cluster.service';
import { isNotNull, isString, isTruthy } from '../utils';

@Injectable()
export class RestaurantTableService {

  private readonly storageKey = 'restaurantTable';
  private readonly logger = this.watchdog.tag('Restaurant Table', 'green');

  public readonly table$ = this.kvRepository.one$(this.storageKey).pipe(
    map((data) => {
      if (data?.value) {
        return data.value as IRestaurantTable;
      }

      return null;
    }),
    shareReplay(1),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly cluster: ClusterService,
    private readonly watchdog: WatchdogService,
    private readonly kvRepository: KVRepository,
    private readonly webSocket: WebsocketService,
    private readonly translate: TranslateService,
    private readonly interaction: InteractionService,
    private readonly intercom: Intercom,
  ) {}

  public initialize(): void {
    this.logger.info('Initialize');

    // Clear table data on logout
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.logouted$),
      switchMap(() => this.kvRepository.delete$(this.storageKey)),
      tap(() => {
        this.logger.info('Cleared table data on logout');
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // Update table data
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((message) => message.type === 'tableInfo'),
      tap((message) => {
        this.logger.info('Table info message', message);
      }),
      mergeMap((message) => {
        return this.updateTable({
          ...message.data,
          clickableMediaConfig: typeof message.data.clickableMediaConfig === 'string'
            ? JSON.parse(message.data.clickableMediaConfig)
            : null,
        });
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((table) => {
      if (this.interaction.idling) {
        this.translate.use(table.language);
      }

      this.translate.setDefaultLang(table.language);
    });

    // Listen websocket echo messages
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((response) => response.type === 'echo'),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      if (message.data?.deviceTurnOff === true) {
        this.logger.info('Device turn off');
        this.intercom.call('device.turnOff');
      }

      if (message.data?.getPlaylistItems === true) {
        this.logger.info('Get playlist items');
        this.intercom.call('playlist.get_items');
      }
    });

    // Send battery level to server
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.intercom.batteryLevel$),
      filter(isNotNull),
      switchMap((batteryLevel) => {
        return this.webSocket.status$.pipe(
          filter(isTruthy),
          map(() => batteryLevel),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.webSocket.send('batteryLevel', status);
    });

    // Send playlist items to server
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.intercom.messages$),
      filter((message) => message.event === 'playlist.items'),
      switchMap((message) => {
        return combineLatest([
          this.table$.pipe(filter(isNotNull)),
          this.webSocket.status$.pipe(filter(isTruthy)),
        ]).pipe(
          take(1),
          map(([table]) => (
            {
              tableId: table.tableId,
              playlistItems: message.data,
            }
          )),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({ tableId, playlistItems }) => {
      this.webSocket.send('echo', { tableId, playlistItems });
    });
  }

  public updateTable(table: IRestaurantTable): Observable<IRestaurantTable> {
    return this.kvRepository.update$({
      key: this.storageKey,
      value: table,
    }).pipe(
      map(entry => entry.value as IRestaurantTable),
      tap((table) => this.logger.debug('Table data updated', table)),
    );
  }

}
