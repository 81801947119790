import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { CORE_APP_ENV, CORE_APP_NAME, CORE_APP_VERSION } from '../../../core.tokens';
import { DebugCardComponent } from './ui';
import {
  DebugAppComponent,
  DebugClusterComponent,
  DebugDeviceComponent,
  DebugPerformanceComponent,
  DebugTableComponent,
} from './stats';
import { ClusterService, RestaurantTableService, UpdateService } from '../../services';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { appReload } from '../../../index';

@Component({
  selector: 'core-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  imports: [
    DebugCardComponent,
    DebugAppComponent,
    DebugTableComponent,
    DebugDeviceComponent,
    DebugClusterComponent,
    DebugPerformanceComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugComponent {

  private readonly clusterLeader$ = this.cluster.leader$.pipe(
    map((leader) => !!leader),
  );

  private readonly clusterMembersCount$ = this.cluster.members$.pipe(
    map((members) => members.size),
  );

  public readonly updateEnabled = this.update.enabled;
  public readonly open = signal<'app' | 'table' | 'cluster' | null>(null);
  public readonly updateStatus = toSignal(this.update.status$);
  public readonly table = toSignal(this.restaurant.table$);

  public readonly clusterLeader = toSignal(this.clusterLeader$, {
    initialValue: false,
  });

  public readonly clusterMembersCount = toSignal(this.clusterMembersCount$, {
    initialValue: 0,
  });

  constructor(
    @Inject(CORE_APP_NAME) public readonly appName: string,
    @Inject(CORE_APP_ENV) public readonly appEnv: string,
    @Inject(CORE_APP_VERSION) public readonly appVersion: string,
    private readonly cluster: ClusterService,
    private readonly update: UpdateService,
    private readonly restaurant: RestaurantTableService,
  ) {}

  public handleToggleApp(event: Event): void {
    event.preventDefault();
    this.open.set(this.open() === 'app' ? null : 'app');
  }

  public handleToggleTable(event: Event): void {
    event.preventDefault();
    this.open.set(this.open() === 'table' ? null : 'table');
  }

  public handleToggleCluster(event: Event): void {
    event.preventDefault();
    this.open.set(this.open() === 'cluster' ? null : 'cluster');
  }

  public handleCheckForUpdate(event: Event): void {
    event.preventDefault();
    this.update.check().subscribe();
  }

  public handleUpdate(event: Event): void {
    event.preventDefault();
    this.update.restartImmediately().subscribe();
  }

  public handleReload(event: Event): void {
    event.preventDefault();
    appReload();
  }

}
