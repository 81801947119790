import { DestroyRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, fromEvent, shareReplay } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { WatchdogService } from './watchdog.service';
import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_STARTUP_DATE,
  CORE_STARTUP_ID,
  CORE_STARTUP_MODE,
  CoreStartupMode,
} from '../../core.tokens';
import { AppMetadata, appReload, UpdateService } from '../../index';

type FilterNever<T, FT = never> = {
  [K in keyof T as T[K] extends FT ? never : K]: T[K];
};

type ClusterMessageTypesMap = {
  'member.new': AppMetadata,
  'member.leader': AppMetadata,
  'member.handshake': AppMetadata,
  'member.remove': never,
  'auth.code': string | null,
  'waiter.call': {
    type: 'main' | 'requestBill' | 'anotherRound';
    status: boolean;
  },
  'repository.event': {
    repository: string;
    event: {
      event?: string;
      key?: unknown;
      value?: unknown;
    };
  },
}

type ClusterMessageTypesMapWithPayload = FilterNever<ClusterMessageTypesMap>;
type ClusterMessageTypesMapWithoutPayload = Omit<ClusterMessageTypesMap, keyof ClusterMessageTypesMapWithPayload>;

export type ClusterMessageTypes = keyof ClusterMessageTypesMap;
export type ClusterMessageTypesWithPayload = keyof ClusterMessageTypesMapWithPayload;
export type ClusterMessageTypesWithoutPayload = keyof ClusterMessageTypesMapWithoutPayload;

export type ClusterMessageData<T extends ClusterMessageTypes> = ClusterMessageTypesMap[T];

export type ClusterMessageTypesMapObject<T extends ClusterMessageTypes> = {
  [K in T]: ClusterMessageData<K> extends never ? {
    type: K;
  } : {
    type: K;
    data: ClusterMessageData<K>;
  };
}[T];

export type ClusterMessage = {
  from: string;
  to?: string;
} & ClusterMessageTypesMapObject<ClusterMessageTypes>;

export type ClusterMember = {
  id: string;
  self: boolean;
  leader: boolean;
  app: Readonly<AppMetadata>;
};

export type ClusterMembers = ReadonlyMap<string, ClusterMember>;

class ClusterMembersSubject extends BehaviorSubject<ClusterMembers> {

  constructor(
    private readonly id: string,
    readonly app: AppMetadata,
  ) {
    super(new Map<string, ClusterMember>([
      [id, { id, self: true, leader: false, app }],
    ]));
  }

  public set(id: string, app: AppMetadata): void {
    const newValue = new Map<string, ClusterMember>(this.value);

    this.next(
      newValue.set(id, {
        id,
        self: id === this.id,
        leader: false,
        app,
      }),
    );
  }

  public setLeader(id: string, app: AppMetadata): void {
    const newValue = new Map<string, ClusterMember>(this.value);

    newValue.forEach((member, memberId) => {
      if (memberId !== id) {
        newValue.set(memberId, {
          ...member,
          leader: false,
        });
      }
    });

    this.next(
      newValue.set(id, {
        id,
        self: id === this.id,
        leader: true,
        app,
      }),
    );
  }

  public delete(member: string): void {
    const newValue = new Map<string, ClusterMember>(this.value);
    newValue.delete(member);
    this.next(newValue);
  }

}

@Injectable()
export class ClusterService {

  public readonly appMetadata: Readonly<AppMetadata> = {
    name: this.appName,
    version: this.appVersion,
    environment: this.appEnv,
    startupId: this.id,
    startupDate: this.startupDate,
    selfUpdate: {
      enabled: this.update.enabled,
      checkStrategy: this.update.updateCheckStrategyTitle,
      activateStrategy: this.update.updateActivateStrategyTitle,
    },
  };

  private readonly logger = this.watchdog.tag('Cluster', 'red');
  private readonly channelKey = this.mode === 'demo' ? 'cluster.demo' : 'cluster';
  private readonly channel = new BroadcastChannel<ClusterMessage>(this.channelKey);
  private readonly elector = createLeaderElection(this.channel);

  private readonly leaderSubject = new BehaviorSubject<boolean | undefined>(undefined);

  private readonly membersSubject = new ClusterMembersSubject(
    this.id,
    this.appMetadata,
  );

  public readonly leader$ = this.leaderSubject.asObservable().pipe(
    distinctUntilChanged(),
  );

  public readonly loading$ = this.leader$.pipe(
    map((leader) => leader === undefined),
    distinctUntilChanged(),
  );

  public readonly members$ = this.membersSubject.asObservable();

  public readonly messages$ = fromEvent(this.channel, 'message').pipe(
    filter((message) => {
      return message.to === undefined || message.to === this.id;
    }),
    shareReplay(100),
  );

  constructor(
    @Inject(CORE_STARTUP_ID) public readonly id: string,
    @Inject(CORE_STARTUP_MODE) public readonly mode: CoreStartupMode,
    @Inject(CORE_STARTUP_DATE) private readonly startupDate: Date,
    @Inject(CORE_APP_NAME) private readonly appName: string,
    @Inject(CORE_APP_VERSION) private readonly appVersion: string,
    @Inject(CORE_APP_ENV) private readonly appEnv: string,
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
    private readonly update: UpdateService,
  ) {}

  public initialize(): void {
    this.logger.info('Initializing');

    // Add self to members
    this.broadcast('member.new', this.appMetadata).then(() => {
      this.logger.debug('Broadcast new member');
    });

    // Handle duplicate leader
    this.elector.onduplicate = async (message) => {
      this.logger.warn('Duplicate leader detected', message);
      await this.channel.close();
      appReload();
    };

    // Await leadership
    fromPromise(this.elector.awaitLeadership()).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.logger.info('Became a leader');

      this.leaderSubject.next(true);
      this.membersSubject.setLeader(this.id, this.appMetadata);

      this.broadcast('member.leader', this.appMetadata).then(() => {
        this.logger.debug('Broadcast new leader');
      });
    });

    // Remove self from members on unload
    fromEvent(window, 'beforeunload').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(async () => {
      await this.broadcast('member.remove');
      this.logger.info('Removing member before unload');
    });

    // Handle messages
    this.messages$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      this.onMessage(message);
    });

    // Log members
    this.membersSubject.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((members) => {
      this.logger.debug('Members', members);
    });
  }

  public get leader(): boolean {
    return !!this.leaderSubject.value;
  }

  public get members(): ClusterMembers {
    return this.membersSubject.value;
  }

  public get leaderId(): string | undefined {
    if (this.leader) {
      return this.id;
    }

    for (const [id, member] of this.members) {
      if (member.leader) {
        return id;
      }
    }

    return undefined;
  }

  public async broadcast<
    T extends ClusterMessageTypesWithPayload,
    D extends ClusterMessageData<T>
  >(type: T, data: D): Promise<void>;

  public async broadcast<
    T extends ClusterMessageTypesWithoutPayload,
  >(type: T, data?: never): Promise<void>;

  public async broadcast<
    T extends ClusterMessageTypesWithPayload,
    D extends ClusterMessageData<T>
  >(type: T, data: D): Promise<void> {
    return this.channel.postMessage({
      from: this.id,
      type: type,
      data: data ? data as any : undefined,
    });
  }

  public async send<
    T extends ClusterMessageTypesWithPayload,
    D extends ClusterMessageData<T>
  >(to: string, type: T, data: D): Promise<void>;

  public async send<
    T extends ClusterMessageTypesWithoutPayload,
  >(to: string, type: T, data?: never): Promise<void>;

  public async send<
    T extends ClusterMessageTypesWithPayload,
    D extends ClusterMessageData<T>
  >(to: string, type: T, data: D): Promise<void> {
    return this.channel.postMessage({
      from: this.id,
      to: to,
      type: type,
      data: data ? data as any : undefined,
    });
  }

  private onMessage(message: ClusterMessage): void {
    switch (message.type) {
      case 'member.new':
        this.membersSubject.set(message.from, message.data);

        if (this.elector.isLeader) {
          this.send(message.from, 'member.leader', this.appMetadata).then(() => {
            this.logger.debug('Sent leader to new member');
          });
        }
        else {
          this.send(message.from, 'member.handshake', this.appMetadata).then(() => {
            this.logger.debug('Sent handshake to new member');
          });
        }
        break;
      case 'member.handshake':
        this.membersSubject.set(message.from, message.data);
        break;
      case 'member.leader':
        this.membersSubject.setLeader(message.from, message.data);
        this.leaderSubject.next(false);
        break;
      case 'member.remove':
        this.membersSubject.delete(message.from);
        break;
    }
  }

}
