<core-debug-ui-items>
  <core-debug-ui-field label="Leader App">
    @if (leader()) {
      <span style="color: #2df702;">Yes</span>
    } @else {
      <span style="color: #f12f2f;">No</span>
    }
  </core-debug-ui-field>
</core-debug-ui-items>

<core-debug-ui-items [label]="'Apps (' + members().length + ')'">
  @for (item of members(); track item.id) {
    <core-debug-ui-field [label]="item.name" class="gap-2">
      @if (item.self) {
        <span style="color: #f2f702;">Self</span>
      }

      @if (item.leader) {
        <span style="color: #2df702; margin-left: 4px;">Leader</span>
      }

      {{ item.id }}
    </core-debug-ui-field>
  }
</core-debug-ui-items>
