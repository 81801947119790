<div class="backdrop"></div>
<div class="box">
  <h1>OUR HOUSE</h1>

  <div class="buttons" coreActivitySection="СallWaiterButtons">
    <button
      [class.active]="callWaiter"
      [disabled]="callWaiterToRepeat || callWaiterToPay"
      (click)="!callWaiterToRepeat && !callWaiterToPay && handlerCallWaiter($event)"
      [coreUserActivityClickTracking]=" callWaiter ? 'CancelCallWaiter' : 'CallWaiter'"
    >
      <img src="/images/hand.svg" alt=""/>
      <div class="content">
        <div class="title">EXCUSE ME - EH</div>
        <div class="description">
          <ng-container *ngIf="callWaiter; else notCallWaiter">
            Your server has been alerted
          </ng-container>
          <ng-template #notCallWaiter>
            Tap to call your server. <br/>We'll be with you shortly!
          </ng-template>
        </div>
      </div>
    </button>

    <button
      [class.active]="callWaiterToRepeat"
      [disabled]="callWaiter || callWaiterToPay"
      (click)="!callWaiter && !callWaiterToPay && handlerCallWaiterToRepeat($event)"
      [coreUserActivityClickTracking]=" callWaiterToRepeat ? 'CancelCallWaiterToRepeat' : 'CallWaiterToRepeat'"
    >
      <img src="/images/wine.svg" alt=""/>
      <div class="content">
        <div class="title">ANOTHER ROUND</div>
        <div class="description">
          <ng-container *ngIf="callWaiterToRepeat; else notCallWaiterToRepeat">
            Your drink is on its way
          </ng-container>
          <ng-template #notCallWaiterToRepeat>
            Tap to order another <br/>round of drinks.
          </ng-template>
        </div>
      </div>
    </button>

    <button
      [class.active]="callWaiterToPay"
      [disabled]="callWaiter || callWaiterToRepeat"
      (click)="!callWaiter && !callWaiterToRepeat && handlerCallWaiterToPay($event)"
      [coreUserActivityClickTracking]=" callWaiterToPay ? 'CancelCallWaiterToPay' : 'CallWaiterToPay'"
    >
      <img src="/images/bill.svg" alt=""/>
      <div class="content">
        <div class="title">REQUEST BILL</div>
        <div class="description">
          <ng-container *ngIf="callWaiterToPay; else notCallWaiterToPay">
            We will prepare your bill <br/>as soon as possible
          </ng-container>
          <ng-template #notCallWaiterToPay>
            Tap to notify your server <br/>and pay your bill
          </ng-template>
        </div>
      </div>
    </button>
  </div>
</div>
