import { of, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export class AuthDemoService {

  public readonly token$ = of('token');

  public readonly authorization$ = this.token$.pipe(
    startWith(undefined),
    map((token) => token === undefined),
    distinctUntilChanged(),
  );

  public readonly authorized$ = this.token$.pipe(
    distinctUntilChanged(),
    filter((token) => token !== undefined),
    map((token) => !!token),
  );

  public readonly logouted$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => !yes),
    map(() => true),
  );

  public readonly logined$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => yes),
    map(() => true),
  );

  public login(token: string): void {}

  public logout(): void {}

}
