import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {

  transform(seconds: number): string {
    if (seconds === Infinity) {
      return '∞';
    }

    if (seconds < 60) {
      return `${ seconds }s`;
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes < 60) {
      if (remainingSeconds === 0) {
        return `${ minutes }m`;
      }

      return `${ minutes }m ${ remainingSeconds }s`;
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingSeconds === 0 && remainingMinutes === 0) {
      return `${ hours }h`;
    }

    if (remainingSeconds === 0) {
      return `${ hours }h ${ remainingMinutes }m`;
    }

    return `${ hours }h ${ remainingMinutes }m ${ remainingSeconds }s`;
  }

}
