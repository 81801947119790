import { Component } from '@angular/core';
import { QrViewComponent } from '@app/components/qr-view/qr-view.component';
import { NgIf } from '@angular/common';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-store-tab',
  standalone: true,
  templateUrl: './store-tab.component.html',
  imports: [
    QrViewComponent,
    NgIf,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./store-tab.component.scss'],
})
export class StoreTabComponent {
  isQRVisible = false;
  title = '';
  description = '';
  description2 = '';
  qrSrc = '';
  imgSrc = '';
  moreData: unknown | undefined;

  showQRCode(
    title: string,
    description: string,
    description2: string,
    imgSrc: string,
    qrSrc: string,
    moreData?: unknown,
  ): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;
    this.qrSrc = qrSrc;
    this.description = description;
    this.description2 = description2;
    this.moreData = moreData ? moreData : undefined;
  }
}
