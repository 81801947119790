import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnDestroy, signal } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  CORE_BACKGROUND_PLAYER_AD_URL,
  CORE_BACKGROUND_PLAYER_URL,
  CORE_DEBUG,
  CORE_STARTUP_ID,
} from '../../../core.tokens';
import {
  ClusterService,
  Intercom,
  NetworkService,
  RestaurantTableService,
  WatchdogService,
  WidgetUiConfigRef,
} from '../../services';
import { IRestaurantTable } from '../../models';

@Component({
  selector: 'core-background-player',
  standalone: true,
  templateUrl: './background-player.component.html',
  styleUrls: ['./background-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundPlayerComponent implements OnDestroy {

  private readonly logger = this.watchdog.tag('Background Player', 'lime');

  public readonly iframeUrl = signal<SafeResourceUrl | null>(null);

  private channelMessagesSubscription: Subscription | null = null;
  private intercomMessagesSubscription: Subscription | null = null;

  constructor(
    @Inject(CORE_STARTUP_ID) private readonly id: string,
    @Inject(CORE_DEBUG) private readonly debug: boolean,
    @Inject(CORE_BACKGROUND_PLAYER_URL) private readonly backgroundPlayerUrl: string | null,
    @Inject(CORE_BACKGROUND_PLAYER_AD_URL) private readonly backgroundPlayerAdUrl: string | null,
    private readonly destroyRef: DestroyRef,
    private readonly sanitizer: DomSanitizer,
    private readonly watchdog: WatchdogService,
    private readonly network: NetworkService,
    private readonly cluster: ClusterService,
    private readonly intercom: Intercom,
    private readonly restaurantTable: RestaurantTableService,
    private readonly widgetUiConfig: WidgetUiConfigRef,
  ) {
    this.network.status$.pipe(
      switchMap((status) => {
        if (!status) {
          return of(null);
        }

        return this.restaurantTable.table$.pipe(
          map((table) => this.prepareConfig(table)),
          map((config) => {
            if (config.providers.length > 0) {
              return btoa(unescape(encodeURIComponent(JSON.stringify(config))));
            }

            return null;
          }),
          map((config) => {
            if (this.backgroundPlayerAdUrl && config) {
              const url = new URL(this.backgroundPlayerAdUrl);
              url.searchParams.set('config', config);
              return url.toString();
            }

            return this.backgroundPlayerUrl;
          }),
        );
      }),
      distinctUntilChanged(),
      map((url) => {
        if (!url) {
          return null;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((url) => {
      this.iframeUrl.set(url);
    });

    fromEvent(window, 'unload').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.intercom.call('playlist.resume');
    });

    this.destroyRef.onDestroy(() => {
      this.intercom.call('playlist.resume');

      this.channelMessagesSubscription?.unsubscribe();
      this.intercomMessagesSubscription?.unsubscribe();
    });
  }

  public onLoadData(event: Event): void {
    const iframe = event.target as HTMLIFrameElement;
    const channel = new MessageChannel();

    this.channelMessagesSubscription?.unsubscribe();
    this.intercomMessagesSubscription?.unsubscribe();

    iframe.contentWindow?.postMessage(
      'dwall-handshake', '*', [channel.port2],
    );

    this.channelMessagesSubscription = fromEvent<MessageEvent>(
      channel.port1, 'message',
    ).subscribe((messageEvent) => {
      const message = JSON.parse(messageEvent.data);

      this.logger.debug('Received', message);

      this.intercom.call(message.method, message.data);
    });

    this.intercomMessagesSubscription = this.intercom.messages$.subscribe((message) => {
      this.logger.debug('Sent', message);

      channel.port1.postMessage(JSON.stringify(message));
    });

    channel.port1.start();
  }

  public onError(event: Event): void {
    this.logger.error('Could not load background player', event);
    this.iframeUrl.set(null);
  }

  public ngOnDestroy(): void {
    this.intercom.call('playlist.resume');
  }

  private prepareConfig(table: IRestaurantTable | null) {
    const config: {
      type: 'order' | 'time',
      every: number,
      bgColor: string,
      debug: boolean,
      providers: {
        id: string,
        [key: string]: unknown
      }[]
    } = {
      type: 'order',
      every: table?.adRunEvery ?? 3,
      bgColor: this.widgetUiConfig.adPlayerBackgroundColor,
      debug: this.debug,
      providers: [],
    };

    const integrations = this.cluster.leader
      ? (
        table?.integrations ?? []
      )
      : (
        table?.sideCarAppsIntegrations[this.id] ?? []
      );

    if (integrations.length) {
      integrations.map(integration => {

        if (integration.provider === 'VAST') {
          config.providers.push({
            id: 'VAST',
            tagUrl: integration.credentials.vastTagUrl,
          });
        }

        if (integration.provider === 'vistarMedia') {
          config.providers.push({
            id: 'VistarMedia',
            apiKey: integration.credentials.apiKey,
            networkId: integration.credentials.networkId,
            venueId: integration.credentials.venueId,
          });
        }
      });
    }

    return config;
  }

}
