import { DestroyRef, Injectable } from '@angular/core';
import { of, shareReplay } from 'rxjs';
import { DeviceWorkSchedulePeriod } from '../models';
import { Intercom } from './intercom.service';
import { debounceTime, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebsocketService } from './websocket.service';
import { KVRepository } from '../repositories';
import { ClusterService } from './cluster.service';
import { isTruthy } from '../utils';

@Injectable()
export class DeviceWorkScheduleService {

  private readonly storageKey = 'deviceWorkSchedule';
  private readonly logger = this.watchdog.tag('Device Work Schedule', 'cyan');

  private readonly schedule$ = this.kvRepository.one$(this.storageKey).pipe(
    map((data) => {
      if (data?.value) {
        return data.value as DeviceWorkSchedulePeriod[];
      }

      return null;
    }),
    shareReplay(1),
  );
  private readonly deviceWorkSchedule = [
    { active: false, day: 0, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 1, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 2, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 3, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 4, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 5, timeFrom: '00:00', timeTo: '00:00' },
    { active: false, day: 6, timeFrom: '00:00', timeTo: '00:00' },
  ];

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly cluster: ClusterService,
    private readonly intercom: Intercom,
    private readonly kvRepository: KVRepository,
    private readonly watchdog: WatchdogService,
    private readonly webSocket: WebsocketService,
  ) {}

  public initialize(): void {
    this.logger.info('Initialize');

// Send the work schedule to the device
    this.cluster.leader$.pipe(
      filter(isTruthy),
      switchMap(() => this.intercom.connected$),
      filter(isTruthy),
      switchMap(() => this.auth.authorized$),
      switchMap((authorized) => {
        if (!authorized) {
          return of(null);
        }

        return this.schedule$;
      }),
      map((schedule) => schedule ?? []),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((schedule) => {
      this.intercom.call('device.work_schedule_periods', schedule);
    });

    // Retry sending the work schedule to the device
    this.cluster.leader$.pipe(
      filter(isTruthy),
      switchMap(() => this.intercom.messages$),
      filter((message) => message.event === 'device.work_schedule_periods.failed'),
      debounceTime(5000),
      switchMap(() => this.schedule$),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((schedule) => {
      this.intercom.call('device.work_schedule_periods', schedule ?? []);
    });

    // Update the work schedule when it changes
    this.cluster.leader$.pipe(
      filter(isTruthy),
      switchMap(() => this.webSocket.messages$),
      filter((response) => response.type === 'tableInfo'),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((response) => {
      this.logger.info('Received: TableInfo message.', response);

      if (response.data.deviceSchedulePeriods) {
        this.update(response.data.deviceSchedulePeriods);
      }

      if (response.data.deviceWorkSchedule) {
        this.intercom.call('device.work_schedule', this.deviceWorkSchedule);
      }
    });
  }

  public update(schedules: DeviceWorkSchedulePeriod[]): void {
    this.schedule$.pipe(
      take(1),
      switchMap((scheduleLocal) => {

        if (schedules?.length > 0) {
          const newSchedule = schedules
            .filter((schedule) => schedule.active)
            .map((schedule) => {
              return {
                active: true,
                id: schedule.id,
                startDay: schedule.startDay,
                startTime: schedule.startTime.split(':').slice(0, 2).join(':'),
                endDay: schedule.endDay,
                endTime: schedule.endTime.split(':').slice(0, 2).join(':'),
              };
            });

          if (!this.areSchedulesEqual(scheduleLocal, newSchedule)) {
            return this.kvRepository.update$({
              key: this.storageKey,
              value: newSchedule,
            });
          } else {
            this.intercom.call('device.work_schedule_periods', newSchedule);
            return of(newSchedule);
          }
        }

        return this.kvRepository.delete$(this.storageKey);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  private areSchedulesEqual(
    scheduleA: DeviceWorkSchedulePeriod[] | null,
    scheduleB: DeviceWorkSchedulePeriod[]
  ): boolean {
    if (!scheduleA || scheduleA.length !== scheduleB.length) {
      return false;
    }

    const sortedA = [...scheduleA].sort((a, b) => a.id - b.id);
    const sortedB = [...scheduleB].sort((a, b) => a.id - b.id);

    return sortedA.every((a, index) => {
      const b = sortedB[index];
      return (
        a.id === b.id &&
        a.startDay === b.startDay &&
        a.startTime === b.startTime &&
        a.endDay === b.endDay &&
        a.endTime === b.endTime
      );
    });
  }

}
