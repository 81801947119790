import { WatchdogService } from '../../services';

export class UserActivityDemoService {

  private readonly logger = this.watchdog.tag('User Activity - Demo Mode', 'crimson');

  constructor(
    private readonly watchdog: WatchdogService,
  ) {}

  public trackClick(event: MouseEvent, screen: string, section: string, target: any): void {
    this.logger.log('click', { event, screen, section, target });
  }

}
