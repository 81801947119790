import { ChangeDetectionStrategy, Component, DestroyRef, Inject, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, merge, of, Subscription, timer } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, share, switchMap, take } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService, ClusterService, NetworkService, WatchdogService } from '../../services';
import { CORE_WEBSOCKET_BASE_URL } from '../../../core.tokens';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { transition, trigger, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn, fadeOut } from 'ng-animate';

@Component({
  selector: 'auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
  imports: [
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('bounceIn', [
      transition(':enter', [
        useAnimation(bounceIn, {
          params: { timing: 0.4 },
        }),
      ]),
    ]),
    trigger('changeBounceIn', [
      transition('* => *', [
        useAnimation(bounceIn, {
          params: { timing: 0.4 },
        }),
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        useAnimation(fadeIn, {
          params: { timing: 0.2 },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOut, {
          params: { timing: 0.2 },
        }),
      ]),
    ]),
  ],
})
export class AuthPageComponent {

  public readonly online = toSignal(this.network.status$, {
    initialValue: this.network.isOnline,
  });

  public readonly code = signal<string | null>(null);

  private readonly logger = this.watchdog.tag('WebSocket', 'magenta');
  private readonly webSocket$ = webSocket({
    url: `${ this.websocketBaseUrl }/ws/auth`,
    openObserver: {
      next: () => this.logger.log('Open'),
    },
    closingObserver: {
      next: () => {
        this.logger.log('Closing');
        this.clearWidgetCode();
      },
    },
    closeObserver: {
      next: () => {
        this.logger.log('Close');
        this.clearWidgetCode();
      },
    },
  });

  constructor(
    @Inject(CORE_WEBSOCKET_BASE_URL) private readonly websocketBaseUrl: string,
    private readonly destroyRef: DestroyRef,
    private readonly translate: TranslateService,
    private readonly cluster: ClusterService,
    private readonly watchdog: WatchdogService,
    private readonly network: NetworkService,
    private readonly auth: AuthService,
  ) {
    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      takeUntilDestroyed(this.destroyRef),
      take(1),
    ).subscribe(() => {
      this.initWorkerWebSocket();
    });

    this.cluster.messages$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      if (message.type === 'auth.code') {
        this.code.set(message.data);
      }

      if (message.type === 'member.new' && this.cluster.leader) {
        this.cluster.send(message.from, 'auth.code', this.code()).then(() => {
          this.logger.debug('Send code to new member', message.from);
        });
      }
    });
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public handlerChangeLanguage(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      this.translate.use(event.target.value);
    }
  }

  private initWorkerWebSocket(): void {
    let wsSub: Subscription | null = null;
    let timeoutSub: Subscription | null = null;
    let heartbeatSub: Subscription | null = null;

    const unsubscribeAll = () => {
      wsSub?.unsubscribe();
      wsSub = null;
      timeoutSub?.unsubscribe();
      timeoutSub = null;
      heartbeatSub?.unsubscribe();
      heartbeatSub = null;
    };

    const initWebSocket = () => {
      unsubscribeAll();

      const share$ = this.webSocket$.pipe(
        filter((response: any) => !!(
          response?.type
        )),
        catchError((error) => {
          this.logger.error(error);
          return EMPTY;
        }),
        share(),
      );

      timeoutSub = merge(of('init'), share$).pipe(
        switchMap(() => of('timeout').pipe(delay(10000))),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((msg) => {
        this.logger.log(msg);

        initWebSocket();
      });

      heartbeatSub = timer(1000, 5000).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => {
        this.logger.debug('Heartbeat send', new Date().toISOString());
        this.webSocket$.next({ type: 'heartbeat' });
      });

      wsSub = share$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(
        (response: any) => this.wsOnMessage(response),
        (error) => {
          this.logger.error(error);

          initWebSocket();
        },
        () => this.logger.log('Complete'),
      );
    };

    this.network.status$.pipe(
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      if (status) {
        initWebSocket();
      }
      else {
        unsubscribeAll();
      }
    });
  }

  private wsOnMessage(response: any): void {
    switch (response.type) {
      case 'widgetCode':
        this.code.set(response.data.widgetCode);
        this.logger.debug('Widget code received', response.data.widgetCode);
        this.cluster.broadcast('auth.code', response.data.widgetCode);
        break;

      case 'jwt':
        this.auth.login(response.data.JWT);
        this.webSocket$.complete();
        this.logger.debug('Token received');
        break;

      case 'heartbeat':
        this.logger.debug('Heartbeat received', response.data?.time);
        break;

      default:
        this.logger.warn('Unknown message', response);
        break;
    }
  }

  private clearWidgetCode(): void {
    this.code.set(null);
    this.cluster.broadcast('auth.code', null);
  }

}
