import { DestroyRef, Injectable } from '@angular/core';
import { merge, shareReplay, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { WatchdogService } from './watchdog.service';
import { KVRepository } from '../repositories';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class AuthService {

  private readonly tokenKey = 'token';
  private readonly logger = this.watchdog.tag('Auth', 'green');

  public readonly token$ = this.kvRepository.one$(this.tokenKey).pipe(
    map((data) => {
      if (data?.value) {
        return data.value as string;
      }

      return localStorage.getItem(this.tokenKey);
    }),
    takeUntilDestroyed(this.destroyRef),
    shareReplay(1),
  );

  public readonly authorization$ = this.token$.pipe(
    startWith(undefined),
    map((token) => token === undefined),
    distinctUntilChanged(),
  );

  public readonly authorized$ = this.token$.pipe(
    distinctUntilChanged(),
    filter((token) => token !== undefined),
    map((token) => !!token),
  );

  public readonly logouted$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => !yes),
    map(() => true),
  );

  public readonly logined$ = this.authorized$.pipe(
    distinctUntilChanged(),
    filter((yes) => yes),
    map(() => true),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
    private readonly kvRepository: KVRepository,
  ) {
    merge(
      this.authorization$.pipe(
        map((status) => status ? 'Authorization pending' : 'Authorization complete'),
      ),
      this.logined$.pipe(map(() => 'Authorized')),
      this.logouted$.pipe(map(() => 'Unauthorized')),
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.logger.info(status);
    });

    this.token$.pipe(
      map((token) => {
        if (token) {
          return token;
        }

        return localStorage.getItem(this.tokenKey);
      }),
      tap((token) => {
        if (token) {
          this.logger.info('Token found in storage');
          this.saveToken(token);
        }
        else {
          this.logger.info('Token not found in storage');
          this.removeToken();
        }
      }),
    );
  }

  public login(token: string): void {
    this.logger.debug('Login');
    this.saveToken(token);
  }

  public logout(): void {
    this.logger.debug('Logout');
    this.removeToken();
  }

  private saveToken(token: string): void {
    this.kvRepository.update$({
      key: this.tokenKey,
      value: token,
    }).subscribe();
    localStorage.setItem(this.tokenKey, token);
  }

  private removeToken(): void {
    this.logger.info('removeToken');
    this.kvRepository.delete$(this.tokenKey).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
    localStorage.removeItem(this.tokenKey);
  }

}
