import { Routes } from '@angular/router';
import { MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { ServiceCentreComponent } from './pages/main-page/service-centre/service-centre.component';
import { MoreTabComponent } from './pages/main-page/more-tab/more-tab.component';
import { RewardsTabComponent } from './pages/main-page/rewards-tab/rewards-tab.component';
import { StoreTabComponent } from './pages/main-page/store-tab/store-tab.component';

export const appRoutes: Routes = [
  {
    path: 'main',
    component: MainPageComponent,
    children: [
      {
        path: 'menu',
        component: MenuTabComponent,
        data: { animation: 'MenuTab', screen: 'CBH Menu' },
      },
      {
        path: 'more',
        component: MoreTabComponent,
        data: { animation: 'MoreTab', screen: 'CBH More' },
      },
      {
        path: 'home',
        component: HomeTabComponent,
        data: { animation: 'HomeTab', screen: 'CBH Home' },
        children: [
          {
            path: 'service',
            component: ServiceCentreComponent,
            data: { animation: 'ServiceCentre', screen: 'CBH ServiceCentre' },
          },
          {
            path: 'special-offer',
            component: SpecialOfferComponent,
            data: { animation: 'SpecialOffer', screen: 'CBH SpecialOffer' },
          },
        ],
      },
      {
        path: 'store',
        component: StoreTabComponent,
        data: { animation: 'StoreTab', screen: 'CBH Store' },
      },
      {
        path: 'rewarded',
        component: RewardsTabComponent,
        data: { animation: 'RewardsTab', screen: 'CBH Rewarded' },
      },
    ],
  },
];
