import { Component } from '@angular/core';

@Component({
  selector: 'app-rewards-tab',
  standalone: true,
  templateUrl: './rewards-tab.component.html',
  styleUrls: ['./rewards-tab.component.scss'],
})
export class RewardsTabComponent {
}
