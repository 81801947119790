<div class="back-navigation">
  <button
    class="back"
    coreActivitySection="QRView"
    coreUserActivityClickTracking="BackToStore"
  >
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.14282 12C7.62854 12 7.28568 11.8286 6.94282 11.4857L2.65711 7.2C1.97139 6.51429 1.97139 5.48571 2.65711 4.8L6.94282 0.514286C7.62854 -0.171429 8.65711 -0.171429 9.34282 0.514286C10.0285 1.2 10.0285 2.22857 9.34282 2.91428L6.25711 6L9.34282 9.08571C10.0285 9.77143 10.0285 10.8 9.34282 11.4857C8.99996 11.8286 8.65711 12 8.14282 12Z" fill="white" />
    </svg>
    Back to store
  </button>
</div>

<div class="box">
  <ng-container>
    <div class="title">{{ title }}</div>
    <div class="description">
      <span>{{ description }}</span>
      <span>{{ description2 }}</span>
    </div>
    <img class="qrImg" [src]="qrSrc" alt="Qr">
    <img class="bottomImg" [class.margin]="moreData" [src]="imgSrc" alt="Image">
  </ng-container>
</div>
