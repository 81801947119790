import { appData } from '../ngsw-config.json';
import player from 'lottie-web';
import { v4 as uuid } from 'uuid';
import { EnvironmentProviders, Provider } from '@angular/core';
import { Routes } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideLottieOptions } from 'ngx-lottie';
import { DBConfig } from 'ngx-indexed-db';
import {
  CoreFeatureToggle,
  CoreUpdateActivateStrategy,
  CoreUpdateCheckStrategy,
  provideCoreApiBaseUrl,
  provideCoreAppEnv,
  provideCoreAppName,
  provideCoreAppVersion,
  provideCoreAuthPagePath,
  provideCoreBackgroundPlayerAdColor,
  provideCoreBackgroundPlayerAdUrl,
  provideCoreBackgroundPlayerUrl,
  provideCoreDebug,
  provideCoreDeviceLocale,
  provideCoreDeviceNumberingSystem,
  provideCoreDeviceTimezone,
  provideCoreFeatureToggle,
  provideCoreMainPagePath,
  provideCoreStartupDate,
  provideCoreStartupId,
  provideCoreStartupMode,
  provideCoreUpdateActivateStrategy,
  provideCoreUpdateCheckStrategy,
  provideCoreWebsocketBaseUrl,
} from './core.tokens';
import { provideAuthInterceptor } from './lib/interceptors/auth.interceptor';
import { provideCoreInitialize } from './core.initialize';
import { provideCoreServiceWorker } from './core.sw';
import { provideCoreDatabase } from './core.database';
import { provideCoreRouter } from './core.router';
import { provideCoreTranslation } from './core.translate';
import { provideCoreServices } from './core.services';
import { initSentry, provideCoreSentryErrorHandler, provideCoreSentryTrace } from './core.sentry';
import { isDemoMode, provideCoreDemo } from './core.demo';

export * from './core.tokens';

export * from './lib/interceptors/auth.interceptor';
export * from './lib/components';
export * from './lib/directives';
export * from './lib/services';
export * from './lib/models';
export * from './lib/utils';

export const isNgServe = (
  window.location.hostname === 'localhost'
  && window.location.port === '4200'
);

export const appReload = () => {
  if (isDemoMode) {
    const url = new URL(window.location.href);
    url.searchParams.set('startupMode', 'demo');
    window.location.href = url.toString();
  }
  else {
    window.location.reload();
  }
};

export interface CoreConfig {
  name: string;
  environment: string;
  debug: boolean;
  authPagePath: string;
  mainPagePath: string;
  api: {
    baseUrl: string;
  };
  websocket: {
    baseUrl: string;
  };
  backgroundPlayer: {
    url: string | null;
    ad: {
      url: string | null;
      color: string | null;
    };
  };
  featureToggle: CoreFeatureToggle;
  routes: Routes;
  database?: DBConfig | DBConfig[];
  serviceWorker?: {
    enabled: boolean;
    script?: string;
  };
  update?: {
    checkStrategy?: CoreUpdateCheckStrategy;
    activateStrategy?: CoreUpdateActivateStrategy;
  };
  sentry?: {
    dsn: string;
    environment: string;
  };
}

export const provideCore = (config: CoreConfig): Array<Provider | EnvironmentProviders> => {
  if (config.sentry) {
    initSentry(config.sentry.dsn, config.sentry.environment);
  }

  const providers: Array<Provider | EnvironmentProviders> = [
    provideCoreStartupId(uuid()),
    provideCoreStartupMode(isDemoMode ? 'demo' : 'live'),
    provideCoreStartupDate(new Date()),
    provideCoreDeviceTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone),
    provideCoreDeviceLocale(Intl.DateTimeFormat().resolvedOptions().locale),
    provideCoreDeviceNumberingSystem(Intl.DateTimeFormat().resolvedOptions().numberingSystem),
    provideCoreAppName(config.name),
    provideCoreAppVersion(appData.version),
    provideCoreAppEnv(config.environment),
    provideCoreDebug(config.debug),
    provideCoreUpdateCheckStrategy(config.update?.checkStrategy),
    provideCoreUpdateActivateStrategy(config.update?.activateStrategy),
    provideCoreAuthPagePath(config.authPagePath),
    provideCoreMainPagePath(config.mainPagePath),
    provideCoreApiBaseUrl(config.api.baseUrl),
    provideCoreWebsocketBaseUrl(config.websocket.baseUrl),
    provideCoreBackgroundPlayerUrl(config.backgroundPlayer.url),
    provideCoreBackgroundPlayerAdUrl(config.backgroundPlayer.ad.url),
    provideCoreBackgroundPlayerAdColor(config.backgroundPlayer.ad.color),
    provideCoreFeatureToggle(config.featureToggle),
    provideCoreInitialize(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    provideAuthInterceptor(),
    provideCoreDatabase(config.database),
    provideCoreTranslation(),
    provideCoreRouter(
      config.mainPagePath,
      config.authPagePath,
      config.routes,
    ),
    provideCoreServices(),
    provideLottieOptions({
      player: () => player,
    }),
  ];

  // Add Demo providers
  if (isDemoMode) {
    providers.push(provideCoreDemo());
  }

  // Add Sentry providers
  if (config.sentry) {
    providers.push(provideCoreSentryErrorHandler());
    providers.push(provideCoreSentryTrace());
  }

  // Add Service Worker provider
  if (config.serviceWorker?.enabled && !isDemoMode) {
    providers.push(provideCoreServiceWorker(config.serviceWorker));
  }

  return providers;
};
