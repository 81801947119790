@let menuList = specialMenus();
@let active = activeMenu();

@if (menuList && menuList.length > 1) {
  <div class="menus" coreActivitySection="SelectLanguage">
    @for (menu of menuList; track menu.id) {
      <div
        class="menus__name"
        (click)="activeMenu.set(menu)"
        [coreUserActivityClickTracking]="'Open Special Offer '+menu.name.split(' ').join(' ')"
      >
        {{ 'languages.'+menu.language+'.label' | translate }}
      </div>
    }
  </div>
}

@if (active) {
  <div class="menu" coreActivitySection="Menu">
    @for (url of active.pagesLocal; track url) {
      <pinch-zoom [properties]="pinchZoomProperties">
        <img class="page" [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    }
  </div>
}
