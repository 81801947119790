import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WatchdogService } from './watchdog.service';

@Injectable()
export class NetworkService {

  private readonly logger = this.watchdog.tag('Network', 'red');

  private readonly statusSubject = new BehaviorSubject<boolean>(navigator.onLine);
  private readonly connectionCountSubject = new BehaviorSubject<number>(navigator.onLine ? 1 : 0);
  private readonly lastConnectionAtSubject = new BehaviorSubject<Date | null>(navigator.onLine ? new Date() : null);
  private readonly lastDisconnectionAtSubject = new BehaviorSubject<Date | null>(navigator.onLine ? null : new Date());

  public readonly status$ = this.statusSubject.asObservable();
  public readonly connectionCount$ = this.connectionCountSubject.asObservable();
  public readonly lastConnectionAt$ = this.lastConnectionAtSubject.asObservable();
  public readonly lastDisconnectionAt$ = this.lastDisconnectionAtSubject.asObservable();

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
  ) {}

  public get isOnline(): boolean {
    return this.statusSubject.getValue();
  }

  public get attempts(): number {
    return this.connectionCountSubject.getValue();
  }

  public get lastConnectionAt(): Date | null {
    return this.lastConnectionAtSubject.getValue();
  }

  public get lastDisconnectionAt(): Date | null {
    return this.lastDisconnectionAtSubject.getValue();
  }

  public initialize(): void {
    this.logger.info('Initializing');

    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false)),
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.logger.info(status ? 'Network is online' : 'Network is offline');

      this.statusSubject.next(status);

      if (status) {
        this.connectionCountSubject.next(this.attempts + 1);
        this.lastConnectionAtSubject.next(new Date());
      }
      else {
        this.lastDisconnectionAtSubject.next(new Date());
      }
    });
  }

}
