import { Component } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-confetti',
  standalone: true,
  templateUrl: './confetti.component.html',
  imports: [
    NgForOf,
  ],
  styleUrls: ['./confetti.component.scss'],
})
export class ConfettiComponent {}
