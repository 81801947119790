import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { CORE_DEVICE_LOCALE, CORE_DEVICE_NUMBERING_SYSTEM, CORE_DEVICE_TIMEZONE } from '../../../../../core.tokens';
import { DebugFieldComponent, DebugItemsComponent } from '../../ui';
import { EMPTY, from, fromEvent, map, merge, Observable, of, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { Intercom } from '../../../../services';
import { BatteryLevel } from '../../../../models/websocket.model';
import { DurationPipe } from '../../../../directives';

declare interface BatteryManager {
  readonly level: number;
  readonly charging: boolean;
  readonly chargingTime: number;
  readonly dischargingTime: number;

  onchargingchange: EventListener;
  onchargingtimechange: EventListener;
  ondischargingtimechange: EventListener;
  onlevelchange: EventListener;
}

declare const navigator: Navigator | Navigator & {
  getBattery(): Promise<BatteryManager>;
};

@Component({
  selector: 'core-debug-device',
  templateUrl: './debug-device.component.html',
  styleUrls: ['./debug-device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
    UpperCasePipe,
    TitleCasePipe,
    DurationPipe,
  ],
})
export class DebugDeviceComponent {

  private readonly viewport$ = merge(
    of({
      width: window.innerWidth,
      height: window.innerHeight,
    }),
    fromEvent(window, 'resize').pipe(
      map(() => (
        {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      )),
    ),
  );

  private readonly batteryLevel$: Observable<BatteryLevel & { chargingTime: number, dischargingTime: number }> = from(
    'getBattery' in navigator
      ? navigator.getBattery()
      : EMPTY,
  ).pipe(
    switchMap((battery: any) => merge(
      of(battery),
      fromEvent(battery, 'levelchange').pipe(map(() => battery)),
      fromEvent(battery, 'chargingchange').pipe(map(() => battery)),
      fromEvent(battery, 'chargingtimechange').pipe(map(() => battery)),
      fromEvent(battery, 'dischargingtimechange').pipe(map(() => battery)),
    )),
  );


  public readonly viewport = toSignal(this.viewport$);
  public readonly intercomBatteryLevel = toSignal(this.intercom.batteryLevel$);
  public readonly batteryLevel = toSignal(this.batteryLevel$);

  public readonly screen = window.screen;

  constructor(
    @Inject(CORE_DEVICE_TIMEZONE) public readonly deviceTimezone: string,
    @Inject(CORE_DEVICE_LOCALE) public readonly deviceLocale: string,
    @Inject(CORE_DEVICE_NUMBERING_SYSTEM) public readonly deviceNumberingSystem: string,
    private readonly intercom: Intercom,
  ) {}

  protected readonly Infinity = Infinity;
}
