<core-debug-ui-items>
  <core-debug-ui-field label="Timezone">{{ deviceTimezone }}</core-debug-ui-field>
  <core-debug-ui-field label="Locale">{{ deviceLocale | uppercase }}</core-debug-ui-field>
  <core-debug-ui-field label="Numbering System">{{ deviceNumberingSystem | titlecase }}</core-debug-ui-field>
  <core-debug-ui-field label="Screen">
    {{ screen.width }} x {{ screen.height }} px
    - Viewport:
    @if (viewport(); as viewport) {
      {{ viewport.width }} x {{ viewport.height }} px
    } @else {
      N/A
    }
  </core-debug-ui-field>
  <core-debug-ui-field label="Intercom Battery Status">
    @if (intercomBatteryLevel(); as battery) {
      <span [style.color]="battery.level <= 0.1 ? '#f12f2f' : (battery.level <= 0.3 ? '#f2f702' : '#2df702')">
        {{ battery.level * 100 }}%
      </span>
      - {{ battery.charging ? 'Charging' : 'Discharging' }}
    } @else {
      N/A
    }
  </core-debug-ui-field>

  <core-debug-ui-field label="WebView Battery Status">
    @if (batteryLevel(); as battery) {
      <span [style.color]="battery.level <= 0.1 ? '#f12f2f' : (battery.level <= 0.3 ? '#f2f702' : '#2df702')">
        {{ (battery.level * 100).toFixed(0) }}%
      </span>
      -
      @if (battery.charging) {
        Charging ~{{ battery.chargingTime | duration }}
      } @else {
        Discharging ~{{ battery.dischargingTime | duration }}
      }
    } @else {
      N/A
    }
  </core-debug-ui-field>
</core-debug-ui-items>
