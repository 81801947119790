import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const mainPageAnimation = trigger('routeAnimations', [
  transition('MenuTab => *', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('MoreTab => StoreTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('MoreTab => HomeTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('MoreTab => RewardsTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('MoreTab => MenuTab', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('HomeTab => StoreTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('HomeTab => RewardsTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('HomeTab => MenuTab', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('HomeTab => MoreTab', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('StoreTab => RewardsTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('StoreTab => MenuTab', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('StoreTab => HomeTab', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('StoreTab => MoreTab', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('RewardsTab <=> MenuTab', [
    query(':enter, :leave',
      style({ position: 'fixed', width: '100%', height: '100%' }), { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateY(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('RewardsTab => *', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),
  transition('MenuTab => *', [
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%' }),
      { optional: true },
    ),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
      ], { optional: true }),
    ]),
  ]),

]);
