import { Component, DestroyRef, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  HappyHoursPeriodNextModel,
  HappyHoursPeriodNowModel,
  HappyHoursService,
  UserActivityClickTrackingDirective,
} from '@core';

@Component({
  selector: 'app-happy-hours',
  standalone: true,
  templateUrl: './happy-hours.component.html',
  imports: [
    TranslateModule,
    NgIf,
    RouterLink,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./happy-hours.component.scss'],
})
export class HappyHoursComponent implements OnInit {

  public readonly timer = {
    show: false,
    now: false,
    active: false,
    timeLeft: '',
    activeCountdownTime: '',
  };

  public readonly notification = {
    timeStart: '',
    timeEnd: '',
  };

  public userInteracts = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly happyHoursService: HappyHoursService,
  ) {}

  ngOnInit(): void {
    this.initWorkerUserInteracts();
    this.initWorkerTimer();
  }

  private initWorkerUserInteracts(): void {
    fromEvent(document, 'click').pipe(
      tap(() => {
        this.userInteracts = true;
      }),
      switchMap(() => timer(9000)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.userInteracts = false;
    });
  }

  private initWorkerTimer(): void {
    this.happyHoursService.status$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((hh) => {
      if (hh instanceof HappyHoursPeriodNowModel || (
        hh instanceof HappyHoursPeriodNextModel && hh.timeLeft.asMinutes() <= 30
      )) {
        this.setTimer(hh);
        this.resetNotification();
      }
      else if (hh instanceof HappyHoursPeriodNextModel && this.userInteracts) {
        this.setNotification(hh);
        this.resetTimer();
      }
      else {
        this.resetTimer();
        this.resetNotification();
      }
    });
  }

  private setTimer(hh: HappyHoursPeriodNextModel | HappyHoursPeriodNowModel): void {
    const isNowHappyHours = hh instanceof HappyHoursPeriodNowModel;
    const timeLeft = hh.timeLeft;
    const showTimer = (
      timeLeft.seconds() >= 55
      || timeLeft.seconds() === 0
      || timeLeft.asMinutes() <= 1
    );
    const countDown = [
      (
        '0' + timeLeft.minutes()
      ).slice(-2),
      (
        '0' + timeLeft.seconds()
      ).slice(-2),
    ].join(':');

    this.timer.now = isNowHappyHours;
    this.timer.show = showTimer;
    this.timer.active = Boolean(timeLeft);
    this.timer.activeCountdownTime = countDown;

    if (showTimer) {
      this.timer.timeLeft = countDown;
    }
  }

  private resetTimer(): void {
    this.timer.show = false;
    this.timer.now = false;
    this.timer.active = false;
    this.timer.timeLeft = '';
  }

  private setNotification(hh: HappyHoursPeriodNextModel | HappyHoursPeriodNowModel): void {
    this.notification.timeStart = hh.timeStart.format('hh:mm A');
    this.notification.timeEnd = hh.timeEnd.format('hh:mm A');
  }

  private resetNotification(): void {
    this.notification.timeStart = '';
    this.notification.timeEnd = '';
  }

}
