<div class="content" #content>
  <div class="slider">
    <ng-container *ngFor="let slide of slides; let i = index">
      <img
        [src]="slide"
        class="slide"
        [class.previous]="i === index - 1 || (i === this.slides.length - 1 && this.index === 0)"
        [class.next]="i === index + 1 || (i === 0 && this.index === this.slides.length - 1)"
        [@slideAnimation]="i === index ? 'active' : (i < index ? 'previous' : 'next')"
        *ngIf="i === index || i === index - 1 || i === index + 1 || (i === 0 && this.index === this.slides.length - 1) || (i === this.slides.length - 1 && this.index === 0)"
        alt="slide">
      />
    </ng-container>
  </div>
</div>
