import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WatchdogService } from './watchdog.service';

@Injectable()
export class InteractionService {

  private readonly logger = this.watchdog.tag('Interaction', 'blue');

  private readonly idleSubject = new BehaviorSubject<boolean>(false);
  private readonly idleCountSubject = new BehaviorSubject<number>(0);
  private readonly lastInteractionAtSubject = new BehaviorSubject<Date>(new Date());
  private readonly lastStartInteractionAtSubject = new BehaviorSubject<Date>(new Date());
  private readonly lastStartIdleAtSubject = new BehaviorSubject<Date | null>(null);

  public readonly idle$ = this.idleSubject.asObservable();
  public readonly status$ = this.idle$.pipe(
    map((idle) => !idle),
  );
  public readonly idleCount$ = this.idleCountSubject.asObservable();
  public readonly lastInteractionAt$ = this.lastInteractionAtSubject.asObservable();
  public readonly lastStartInteractionAt$ = this.lastStartInteractionAtSubject.asObservable();
  public readonly lastStartIdleAt$ = this.lastStartIdleAtSubject.asObservable();

  constructor(
    private readonly watchdog: WatchdogService,
  ) {}

  public get idling(): boolean {
    return this.idleSubject.getValue();
  }

  public get interacting(): boolean {
    return !this.idling;
  }

  public get lastInteractionAt(): Date {
    return this.lastInteractionAtSubject.getValue();
  }

  public get lastStartInteractionAt(): Date {
    return this.lastStartInteractionAtSubject.getValue();
  }

  public get lastStartIdleAt(): Date | null {
    return this.lastStartIdleAtSubject.getValue();
  }

  public get idleCount(): number {
    return this.idleCountSubject.getValue();
  }

  public interact(): void {
    this.logger.info('User activity detected');
    this.lastInteractionAtSubject.next(new Date());

    if (this.idling) {
      this.idleSubject.next(false);
      this.lastStartInteractionAtSubject.next(new Date());
    }
  }

  public idle(): void {
    if (this.idling) {
      this.logger.info('User already idle');
      return;
    }

    this.logger.info('User idle detected');
    this.lastStartIdleAtSubject.next(new Date());

    this.idleSubject.next(true);
    this.idleCountSubject.next(this.idleCountSubject.getValue() + 1);
  }


}
