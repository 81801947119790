import { BehaviorSubject, Subject, switchMap } from 'rxjs';
import { MessageMap, WebSocketResponse } from '../../models/websocket.model';
import { filter, tap } from 'rxjs/operators';
import { ClusterService, Intercom, WatchdogService } from '../../services';

export class WebsocketDemoService {

  private readonly logger = this.watchdog.tag('Websocket - Demo Mode', 'magenta');

  public readonly status$ = new BehaviorSubject<boolean>(false);
  public readonly connectionCount$ = new BehaviorSubject<number>(0);
  public readonly lastConnectionAt$ = new BehaviorSubject<Date | null>(null);
  public readonly lastDisconnectionAt$ = new BehaviorSubject<Date | null>(null);

  private readonly messageSubject = new Subject<WebSocketResponse>();
  public readonly messages$ = this.messageSubject.asObservable();

  constructor(
    private readonly watchdog: WatchdogService,
    private readonly cluster: ClusterService,
    private readonly intercom: Intercom,
  ) {}

  public initialize(): void {
    this.logger.info('Initialize');

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      tap(() => {
        this.status$.next(true);
        this.lastConnectionAt$.next(new Date());
        this.connectionCount$.next(this.connectionCount$.value + 1);
      }),
      switchMap(() => this.intercom.messages$),
      filter(message => message.event.startsWith('demo.')),
    ).subscribe((message) => {
      switch (message.event) {
        case 'demo.events':
          this.messageSubject.next(message.data);
          break;
      }
    });
  }

  public send<K extends keyof MessageMap>(type: K, data: MessageMap[K]): void {
    this.logger.info('Send', type, data);
  }

  public sendAuthToken(): void {
    this.logger.info('Send Auth Token');
  }

}
