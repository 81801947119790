import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { WatchdogService } from './lib/services';
import {
  CORE_APP_VERSION,
  CORE_DEVICE_LOCALE,
  CORE_DEVICE_NUMBERING_SYSTEM,
  CORE_DEVICE_TIMEZONE,
  CORE_STARTUP_DATE,
} from './core.tokens';

export const initSentry = (dsn: string, environment: string) => {
  Sentry.init({
    dsn,
    environment,
  });
};

export const provideCoreSentryErrorHandler = () => (
  [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        extractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
          console.error('Sentry Error Handler:', error);
          return defaultExtractor(error);
        },
      }),
    },
    provideAppInitializer(() => {
      const logger = inject(WatchdogService).tag('App', 'red');
      const startupDate = inject(CORE_STARTUP_DATE);
      const appVersion = inject(CORE_APP_VERSION);
      const deviceTimezone = inject(CORE_DEVICE_TIMEZONE);
      const deviceLocale = inject(CORE_DEVICE_LOCALE);
      const deviceNumberingSystem = inject(CORE_DEVICE_NUMBERING_SYSTEM);

      logger.log('Sentry App Initializer');

      Sentry.setTag('startup_year', startupDate.getUTCFullYear().toString());
      Sentry.setTag('startup_month', startupDate.getUTCMonth().toString());
      Sentry.setTag('startup_day', startupDate.getUTCDate().toString());
      Sentry.setTag('version', appVersion);
      Sentry.setTag('device_timezone', deviceTimezone);
      Sentry.setTag('device_locale', deviceLocale);
      Sentry.setTag('device_numbering_system', deviceNumberingSystem);
    }),
  ]
);

export const provideCoreSentryTrace = () => (
  [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      const logger = inject(WatchdogService).tag('App', 'red');

      inject(Sentry.TraceService);

      logger.log('Trace Initialized');
    }),
  ]
);
