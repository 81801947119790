import {
  AuthService,
  ClusterService,
  Intercom,
  UserActivityService,
  WatchdogService,
  WebsocketService,
} from './lib/services';
import { AuthDemoService, UserActivityDemoService, WebsocketDemoService } from './lib/demo/services';
import { EnvironmentProviders, Provider } from '@angular/core';

const initUrlParams = new URLSearchParams(window.location.search);

export const isDemoMode = initUrlParams.get('startupMode') === 'demo';

export const provideCoreDemo = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: AuthService,
    useFactory: () => {
      return new AuthDemoService();
    },
  },
  {
    provide: WebsocketService,
    useFactory: (watchdog: WatchdogService, swarm: ClusterService, intercom: Intercom) => {
      return new WebsocketDemoService(watchdog, swarm, intercom);
    },
    deps: [WatchdogService, ClusterService, Intercom],
  },
  {
    provide: UserActivityService,
    useFactory: (watchdog: WatchdogService) => {
      return new UserActivityDemoService(watchdog);
    },
    deps: [WatchdogService],
  },
];
