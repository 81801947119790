import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DebugFieldComponent, DebugItemsComponent } from '../../ui';
import { ClusterService } from '../../../../services';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import animals from '../../data/animals.json';

@Component({
  selector: 'core-debug-cluster',
  templateUrl: './debug-cluster.component.html',
  styleUrls: ['./debug-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
  ]
})
export class DebugClusterComponent {

  public readonly leader = toSignal(
    this.cluster.leader$.pipe(
      map((leader) => !!leader),
    ), {
      initialValue: false,
    },
  );

  public readonly members = toSignal(
    this.cluster.members$.pipe(
      map((members) => {
        return Array.from(members.values()).map((member) => {
          return {
            ...member,
            name: this.getAnimalNameFromId(member.id),
          };
        });
      }),
    ),
    {
      initialValue: [],
    },
  );

  constructor(
    private readonly cluster: ClusterService,
  ) {}

  private getAnimalNameFromId(id: string): string {
    const length = id.length;

    let sum = 0;
    for (let i = 0; i < length; i++) {
      sum += id.charCodeAt(i);
    }

    return animals[sum % animals.length] as unknown as string;
  }

}
