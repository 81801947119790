import { DestroyRef, Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { combineLatest, forkJoin, of, shareReplay } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { RGBA } from 'color-blend/dist/types';
import { adjustColorLightness, hexToRgba, isBoolean, isNumber, isString, tapSubscribed } from '../utils';
import { CORE_BACKGROUND_PLAYER_AD_COLOR, CORE_FEATURE_TOGGLE, CoreFeatureToggle } from '../../core.tokens';
import { FileCacheModel, WidgetUIConfiguration } from '../models';
import { RestaurantTableService } from './restaurant-table.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FileCacheRepository } from '../repositories/file-cache.repository';
import { WatchdogService } from './watchdog.service';
import { ClusterService } from './cluster.service';
import { WebsocketService } from './websocket.service';
import { FileCacheService } from './file-cache.service';
import { AuthService } from './auth.service';

@Injectable()
export class WidgetUiConfigRef {

  private readonly logger = this.watchdog.tag('Widget Ui Config', 'magenta');

  public readonly config$ = this.restaurantTable.table$.pipe(
    map((table) => {
      return {
        config: table?.widgetUIConfig,
        features: table?.features,
      };
    }),
    switchMap(({ config, features }) => {
      const preparedFile = (url: string | null | undefined) => {
        if (!isString(url) || url.startsWith('data:')) {
          return of(url ? this.domSanitizer.bypassSecurityTrustUrl(url) : null);
        }

        return this.fileCacheRepository.one$(url).pipe(
          map((value) => {
            const file = value
              ? new FileCacheModel(value)
              : undefined;

            return file ?
              this.domSanitizer.bypassSecurityTrustUrl(file.objectUrl)
              : null;
          }),
        );
      };

      return combineLatest([
        of(config),
        of(features),
        preparedFile(config?.logo),
        preparedFile(config?.pages?.menu?.icon),
        preparedFile(config?.pages?.serviceCenter?.icon),
        preparedFile(config?.pages?.invoice?.icon),
      ]);
    }),
    map(([config, features, logo, menuIcon, serviceCenterIcon, invoiceIcon]) => {
      return {
        ...config,
        logo,
        callWaiterButtons: {
          callWaiter: {
            ...config?.callWaiterButtons?.callWaiter,
            enabled: config?.callWaiterButtons?.callWaiter?.enabled && features?.withCallWaiter,
          },
          requestBill: {
            ...config?.callWaiterButtons?.requestBill,
            enabled: config?.callWaiterButtons?.requestBill?.enabled && features?.withCallWaiterToPay,
          },
          anotherRound: {
            ...config?.callWaiterButtons?.anotherRound,
            enabled: config?.callWaiterButtons?.anotherRound?.enabled && features?.withCallWaiterToRepeat,
          },
        },
        pages: {
          ...config?.pages,
          menu: {
            ...config?.pages?.menu,
            icon: menuIcon,
          },
          serviceCenter: {
            ...config?.pages?.serviceCenter,
            icon: serviceCenterIcon,
          },
          invoice: {
            ...config?.pages?.invoice,
            icon: invoiceIcon,
          },
        },
      };
    }),
    shareReplay(1),
  );

  public readonly config = toSignal(this.config$, {
    initialValue: null,
  });

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    @Inject(CORE_BACKGROUND_PLAYER_AD_COLOR) private readonly backgroundAdPlayerColor: string | null,
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly cluster: ClusterService,
    private readonly watchdog: WatchdogService,
    private readonly webSocket: WebsocketService,
    private readonly restaurantTable: RestaurantTableService,
    private readonly fileCacheRepository: FileCacheRepository,
    private readonly fileCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  public initialize(): void {
    this.logger.info('Initialize');

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.logouted$),
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared widget ui config on logout')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.cluster.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((response) => response.type === 'tableInfo'),
      switchMap((response) => this.sync(response.data.widgetUIConfig as WidgetUIConfiguration)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public sync(config: WidgetUIConfiguration) {
    return this.restaurantTable.table$.pipe(
      take(1),
      tapSubscribed(() => {
        this.logger.debug('Start sync config');
      }),
      map((table) => table?.widgetUIConfig ?? null),
      switchMap((oldConfig) => {
        const toAdd = [];
        const toDelete = [];

        if (oldConfig?.logo !== config.logo) {
          if (config.logo) {
            toAdd.push(config.logo);
          }

          if (oldConfig?.logo) {
            toDelete.push(oldConfig.logo);
          }
        }

        if (oldConfig?.pages?.menu?.icon !== config.pages?.menu?.icon) {
          if (config.pages?.menu?.icon) {
            toAdd.push(config.pages.menu.icon);
          }

          if (oldConfig?.pages?.menu?.icon) {
            toDelete.push(oldConfig.pages.menu.icon);
          }
        }

        if (oldConfig?.pages?.serviceCenter?.icon !== config.pages?.serviceCenter?.icon) {
          if (config.pages?.serviceCenter?.icon) {
            toAdd.push(config.pages.serviceCenter.icon);
          }

          if (oldConfig?.pages?.serviceCenter?.icon) {
            toDelete.push(oldConfig.pages.serviceCenter.icon);
          }
        }

        if (oldConfig?.pages?.invoice?.icon !== config.pages?.invoice?.icon) {
          if (config.pages?.invoice?.icon) {
            toAdd.push(config.pages.invoice.icon);
          }

          if (oldConfig?.pages?.invoice?.icon) {
            toDelete.push(oldConfig.pages.invoice.icon);
          }
        }

        return combineLatest([
          this.fileCache.downloadFiles(toAdd),
          this.fileCache.bulkDelete(toDelete),
        ]);
      }),
    );
  }

  public clear() {
    return this.restaurantTable.table$.pipe(
      take(1),
      switchMap((table) => {
        if (!table) {
          return of(null);
        }

        const urls = [];

        if (table.widgetUIConfig.logo) {
          urls.push(table.widgetUIConfig.logo);
        }

        if (table.widgetUIConfig.pages?.menu?.icon) {
          urls.push(table.widgetUIConfig.pages.menu.icon);
        }

        if (table.widgetUIConfig.pages?.serviceCenter?.icon) {
          urls.push(table.widgetUIConfig.pages.serviceCenter.icon);
        }

        if (table.widgetUIConfig.pages?.invoice?.icon) {
          urls.push(table.widgetUIConfig.pages.invoice.icon);
        }

        if (urls.length === 0) {
          return of(null);
        }

        return forkJoin(urls.map((url) => {
          return this.fileCacheRepository.delete$(url).pipe(
            map(() => url),
          );
        }));
      }),
    );
  }

  get widgetUIConfig() {
    return this.config();
  }

  get toolbarButtonsCount(): number {
    let count = 0;

    if (this.featureToggle.withTabBar) {
      if (this.menuPageEnabled) {
        count++;
      }

      if (this.serviceCenterPageEnabled || this.callWaiterButtonEnabled) {
        count++;
      }

      if (this.invoicePageEnabled) {
        count++;
      }
    }
    else if (
      this.callWaiterButtonEnabled ||
      this.menuPageEnabled ||
      this.serviceCenterPageEnabled ||
      this.invoicePageEnabled
    ) {
      count++;
    }

    return count;
  }

  get logo(): SafeUrl | string {
    return this.widgetUIConfig?.logo || '';
  }

  get idleDelay(): number {
    if (isNumber(this.featureToggle.idleDelay)) {
      return this.featureToggle.idleDelay ?? 30;
    }

    return this.widgetUIConfig?.idleDelay ?? 30;
  }

  get tabBarAutoHide(): boolean {
    if (isBoolean(this.featureToggle.tabBarAutoHide)) {
      return this.featureToggle.tabBarAutoHide;
    }

    return this.widgetUIConfig?.tabBarAutoHide ?? false;
  }

  get primaryColor(): string {
    return this.widgetUIConfig?.colors?.primary || '#8363EC';
  }

  get primaryColorLight(): string {
    return adjustColorLightness(this.primaryColor, 30);
  }

  get primaryColorDark(): string {
    return adjustColorLightness(this.primaryColor, -30);
  }

  get secondaryColor(): string {
    return this.widgetUIConfig?.colors?.secondary || '#000000';
  }

  get secondaryColorLight(): string {
    return adjustColorLightness(this.secondaryColor, 30);
  }

  get secondaryColorDark(): string {
    return adjustColorLightness(this.secondaryColor, -30);
  }

  get tertiaryColor(): string {
    return this.widgetUIConfig?.colors?.tertiary || '#FCFCFC';
  }

  get tertiaryColorLight(): string {
    return adjustColorLightness(this.tertiaryColor, 30);
  }

  get tertiaryColorDark(): string {
    return adjustColorLightness(this.tertiaryColor, -30);
  }

  get adPlayerBackgroundColor(): string {
    if (this.backgroundAdPlayerColor) {
      return this.backgroundAdPlayerColor ?? '#000000';
    }

    return this.tertiaryColor;
  }

  get callWaiterButtonEnabled(): boolean {
    return (
      this.featureToggle.withCallWaiterMain &&
      (
        this.widgetUIConfig?.callWaiterButtons?.callWaiter.enabled || false
      )
    );
  }

  get callWaiterButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons?.callWaiter.name || '';
  }

  get callWaiterButtonLightColor(): RGBA | null {
    if (this.featureToggle.ambientLight.callWaiter) {
      return this.featureToggle.ambientLight.callWaiter;
    }

    if (this.widgetUIConfig?.callWaiterButtons?.callWaiter.lightsColor) {
      return hexToRgba(this.widgetUIConfig.callWaiterButtons.callWaiter.lightsColor);
    }

    return null;
  }

  get anotherRoundButtonEnabled(): boolean {
    return (
      this.featureToggle.withCallWaiterAnotherRound &&
      (
        this.widgetUIConfig?.callWaiterButtons?.anotherRound.enabled || false
      )
    );
  }

  get anotherRoundButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons?.anotherRound.name || '';
  }

  get anotherRoundButtonLightColor(): RGBA | null {
    if (this.featureToggle.ambientLight.callWaiter) {
      return this.featureToggle.ambientLight.callWaiterToRepeat;
    }

    if (this.widgetUIConfig?.callWaiterButtons?.anotherRound.lightsColor) {
      return hexToRgba(this.widgetUIConfig.callWaiterButtons.anotherRound.lightsColor);
    }

    return null;
  }

  get requestBillButtonEnabled(): boolean {
    return (
      this.featureToggle.withCallWaiterRequestBill &&
      (
        this.widgetUIConfig?.callWaiterButtons?.requestBill.enabled || false
      )
    );
  }

  get requestBillButtonName(): string {
    return this.widgetUIConfig?.callWaiterButtons?.requestBill.name || '';
  }

  get requestBillButtonLightColor(): RGBA | null {
    if (this.featureToggle.ambientLight.callWaiter) {
      return this.featureToggle.ambientLight.callWaiterToPay;
    }

    if (this.widgetUIConfig?.callWaiterButtons?.requestBill.lightsColor) {
      return hexToRgba(this.widgetUIConfig.callWaiterButtons.requestBill.lightsColor);
    }

    return null;
  }

  get menuPageEnabled(): boolean {
    return this.widgetUIConfig?.pages.menu.enabled || false;
  }

  get menuPageName(): string {
    return this.widgetUIConfig?.pages.menu.name || '';
  }

  get menuPageIcon(): SafeUrl | string {
    return this.widgetUIConfig?.pages.menu.icon || '';
  }

  get serviceCenterPageEnabled(): boolean {
    return (
      this.featureToggle.withServiceCentre &&
      (
        this.widgetUIConfig?.pages.serviceCenter.enabled || false
      )
    );
  }

  get serviceCenterPageName(): string {
    return this.widgetUIConfig?.pages.serviceCenter.name || '';
  }

  get serviceCenterPageIcon(): SafeUrl | string {
    return this.widgetUIConfig?.pages.serviceCenter.icon || '';
  }

  get invoicePageEnabled(): boolean {
    return (
      this.featureToggle.withInvoice &&
      (
        this.widgetUIConfig?.pages.invoice.enabled || false
      )
    );
  }

  get invoicePageName(): string {
    return this.widgetUIConfig?.pages.invoice.name || '';
  }

  get invoicePageIcon(): SafeUrl | string {
    return this.widgetUIConfig?.pages.invoice.icon || '';
  }

}
