import { DestroyRef, inject } from '@angular/core';
import { repeat, Subject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ClusterService } from '../services';
import { RepositoryEvent, RepositoryKey } from './repository.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export abstract class Repository<
  T extends Record<string, any>,
  KN extends keyof TK,
  I extends boolean = false,
  TN extends T | Omit<T, KN> = I extends false ? T : Omit<T, KN>,
  TK extends RepositoryKey<T> = RepositoryKey<T>,
  KV extends TK[KN] = TK[KN],
> {

  private readonly destroyRef = inject(DestroyRef);
  private readonly cluster = inject(ClusterService);

  private readonly eventsSubject = new Subject<
    RepositoryEvent<T, KN, TK, KV>
  >();

  private readonly events$ = this.eventsSubject.asObservable();

  protected constructor(
    public readonly db: NgxIndexedDBService,
    public readonly storeName: string,
    public readonly primaryKey: KN,
  ) {
    this.cluster.messages$.pipe(
      filter((message) => {
        return message.type === 'repository.event' && message.data.repository === this.storeName;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      if (message.type === 'repository.event') {
        this.eventsSubject.next(message.data.event as RepositoryEvent<T, KN, TK, KV>);
      }
    });
  }

  private emitEvent(event: RepositoryEvent<T, KN, TK, KV>) {
    this.eventsSubject.next(event);
    this.cluster.broadcast('repository.event', {
      repository: this.storeName,
      event,
    }).then();
  }

  public add$(value: TN) {
    return this.db.add<T>(this.storeName, value as T).pipe(
      tap((value: T) => {
        this.emitEvent({
          event: 'add',
          key: value[this.primaryKey as any] as KV,
          value,
        });
      }),
    );
  }

  public update$(value: T) {
    return this.db.update(this.storeName, value).pipe(
      tap(() => {
        this.emitEvent({
          event: 'update',
          key: value[this.primaryKey as any] as KV,
          value,
        });
      }),
    );
  }

  public delete$(key: KV) {
    return this.db.deleteByKey(this.storeName, key as any).pipe(
      tap(() => {
        this.emitEvent({
          event: 'delete',
          key,
        });
      }),
    );
  }

  public one$(key: KV) {
    return this.db.getByKey<T | undefined>(this.storeName, key as any).pipe(
      repeat({
        delay: () => this.events$.pipe(
          filter((event) => {
            if (event.event === 'clear') {
              return true;
            }

            return event.key === key;
          }),
          debounceTime(300),
        ),
      }),
    );
  }

  public all$() {
    return this.db.getAll<T>(this.storeName).pipe(
      repeat({
        delay: () => this.events$.pipe(
          debounceTime(300),
        ),
      }),
    );
  }

  public allByIndex$(indexName: KN, keyRange: IDBKeyRange) {
    return this.db.getAllByIndex<T>(this.storeName, indexName as string, keyRange).pipe(
      repeat({
        delay: () => this.events$.pipe(
          debounceTime(300),
        ),
      }),
    );
  }

  public clear$() {
    return this.db.clear(this.storeName).pipe(
      tap(() => {
        this.emitEvent({
          event: 'clear',
        });
      }),
    );
  }

}
